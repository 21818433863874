<template>
  <div class="min-w-[301.5px] max-w-[301.5px]">
    <div
      :id="`invoice-thermal-container${orderId ? `-${orderId}` : ''}`"
      class="invoice-thermal-container font-medium text-[12px] leading-[18px] bg-white pb-10 text-black"
      :class="[
        useConfig().isBrandedWholesaleReceiptTemplate ? 'pt-2' : 'pt-20',
      ]"
    >
      <div
        :class="[
          useConfig().isBrandedWholesaleReceiptTemplate
            ? 'mb-[5px]'
            : 'mb-[15px]',
        ]"
        class="px-[15px]"
      >
        <!-- LOGO -->
        <div
          v-if="
            generalStore.printAssets?.print_logo &&
            generalStore.printAssets?.print_logo?.split('base64,')?.[1] &&
            (useConfig().isPosHospitality ||
              useConfig().isBrandedWholesaleReceiptTemplate)
          "
          class="max-h-[75px] max-w-[175px] w-full flex items-center justify-center"
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate
              ? 'mb-[5px]'
              : 'mb-[10px]',
          ]"
        >
          <img
            v-if="
              generalStore.printAssets?.print_logo &&
              generalStore.printAssets?.print_logo?.split('base64,')?.[1] &&
              (useConfig().isPosHospitality ||
                useConfig().isBrandedWholesaleReceiptTemplate)
            "
            :src="generalStore.printAssets?.print_logo"
            alt="Logo"
            class="mr-auto object-contain max-h-[75px] max-w-[175px]"
          />
        </div>
        <div class="mb-[10px]" v-else>
          <p class="leading-[20px] font-bold text-[20px]">
            {{ settingsStore.settings.general?.name }}
          </p>
        </div>

        <!-- HEAD INFO -->
        <div>
          <div
            :class="{
              'border-dashed border-b border-black ':
                !useConfig().isBrandedWholesaleReceiptTemplate,
              'mb-[5px] mt-[10px]':
                !useConfig().isBrandedWholesaleReceiptTemplate,
              'mt-[5px]': useConfig().isBrandedWholesaleReceiptTemplate,
            }"
            class=""
            v-if="useConfig().isBrandedWholesaleReceiptTemplate"
          >
            <p
              v-if="
                reportData?.meta?.store?.address?.address ||
                settingsStore.settings.general?.meta_data
              "
              :class="[
                useConfig().isBrandedWholesaleReceiptTemplate
                  ? 'text-[10px]'
                  : 'text-[12px]',
              ]"
              class="leading-[18px] max-w-[300px]"
            >
              <span v-if="settingsStore.settings.general?.meta_data">
                {{ settingsStore.settings.general?.meta_data + ", " }}
              </span>
              <span v-if="reportData?.meta?.store?.address?.address">
                {{ reportData?.meta?.store?.address?.address }},{{
                  reportData?.meta?.store?.address?.city
                }}
                {{ reportData?.meta?.store?.address?.province?.code }}
                {{ reportData?.meta?.store?.address?.postalcode }}
              </span>
            </p>
            <div
              :class="{
                'mb-[5px]': !useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              class="flex gap-[10px]"
            >
              <p
                v-if="
                  reportData?.meta?.store?.abn || reportData?.meta?.store?.phone
                "
                :class="{
                  'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
                  'text-[12px]': !useConfig().isBrandedWholesaleReceiptTemplate,
                }"
                class="leading-[18px]"
              >
                <span v-if="reportData?.meta?.store?.abn"
                  >ABN:
                  {{ reportData?.meta?.store?.abn }}
                </span>
                <span v-if="reportData?.meta?.store?.phone">
                  Phone:
                  {{ reportData?.meta?.store?.phone }}</span
                >
              </p>
            </div>
          </div>
          <p
            class="text-[18px] leading-[27px] font-bold capitalize"
            v-if="
              (useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale) ||
              (!useConfig().isDefaultReceiptTemplate &&
                !useConfig().isBrandedWholesaleReceiptTemplate)
            "
          >
            {{ reportData?.meta?.order?.type }}
          </p>
          <p class="font-bold text-[14px] leading-[20px]">
            <span v-if="useConfig().isBrandedWholesaleReceiptTemplate">
              Tax
            </span>
            Inv #{{ reportData?.meta?.order?.number }}
          </p>
          <p
            v-if="!useConfig().isBrandedWholesaleReceiptTemplate"
            class="text-[12px] leading-[20px] font-bold mt-[5px]"
          >
            {{ reportData?.meta?.store?.name }}
          </p>
        </div>
        <div
          class="flex flex-col gap-[5px] font-bold text-[12px] leading-[18px]"
        >
          <template
            v-if="
              (useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale) ||
              useConfig().isBrandedWholesaleReceiptTemplate
            "
          >
            <p
              v-if="
                reportData?.meta?.order &&
                !useConfig().isBrandedWholesaleReceiptTemplate
              "
            >
              Placed:
              {{ formattedPlacedDate }}
            </p>
            <p
              :class="{
                'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              v-if="!useConfig().isPrintHideTargetDate"
            >
              Target:
              {{
                route.name === useRoutesNames().posCheckoutThankyouId ||
                !reportData?.meta?.order?.target_date?.[0] ||
                reportData?.meta?.order?.is_express
                  ? "ASAP"
                  : reportData?.meta?.order?.target_date?.[0]
                    ? useDateFormatLib(
                        reportData?.meta?.order?.target_date?.[0],
                        `ddd, dd mmm, yy 'at' hh:MM TT`
                      )
                    : ""
              }}
            </p>
            <p
              v-if="
                reportData?.meta?.order?.sales_manager_firstname &&
                !useConfig().isBrandedWholesaleReceiptTemplate
              "
              class="text-[12px] leading-[18px]"
            >
              Sales Person:
              {{ reportData?.meta?.order?.sales_manager_firstname }}
            </p>
          </template>
        </div>
      </div>

      <!-- CUSTOMER INFO (WHOLESALE) -->
      <div
        v-if="
          useConfig().isPosWholesale ||
          !useConfig().isDefaultReceiptTemplate ||
          (!useConfig().isPosWholesale && useConfig().isDefaultReceiptTemplate)
        "
        class="border-t border-black customer"
        :class="{
          'bg-black text-white':
            !useConfig().isDefaultReceiptTemplate ||
            (!useConfig().isPosWholesale &&
              useConfig().isDefaultReceiptTemplate),
        }"
      >
        <div
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate
              ? 'px-[15px] py-[10px]'
              : 'p-[15px]',
          ]"
          class="p-[15px] flex flex-col gap-[5px] text-[16px] leading-[20px]"
        >
          <!-- NAME -->
          <p class="font-bold">
            {{ reportData?.meta?.customer?.name ?? "Guest" }}
          </p>

          <p
            class="font-bold capitalize"
            v-if="
              useConfig().isDefaultReceiptTemplate &&
              !useConfig().isPosWholesale
            "
          >
            {{ reportData?.meta?.order?.type }}
          </p>

          <template
            v-if="
              (useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale) ||
              (!useConfig().isDefaultReceiptTemplate &&
                !useConfig().isBrandedWholesaleReceiptTemplate)
            "
          >
            <!-- BAY NUMBER ADDRESS -->
            <p
              class="font-bold"
              v-if="
                reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
                reportData?.meta?.order?.type !== OrderType.PICKUP &&
                (reportData?.meta?.customer?.bay_number ||
                  (reportData?.meta?.order?.address &&
                    !reportData?.meta?.customer?.hide_address_on_receipt))
              "
            >
              <span v-if="reportData?.meta?.customer?.bay_number"
                ><span v-if="useConfig().isDefaultReceiptTemplate"
                  >Bay Number:</span
                >
                {{ reportData?.meta?.customer?.bay_number }}</span
              ><span
                v-if="
                  !useConfig().isDefaultReceiptTemplate &&
                  reportData?.meta?.order?.address &&
                  !reportData?.meta?.customer?.hide_address_on_receipt
                "
                >{{ reportData?.meta?.customer?.bay_number ? ", " : ""
                }}{{ reportData?.meta?.order?.address }},
                {{ reportData?.meta?.order?.city }}</span
              >
            </p>

            <!-- ADDRESS -->
            <div
              v-if="
                reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
                reportData?.meta?.order?.type !== OrderType.PICKUP &&
                reportData?.meta?.order?.address &&
                !reportData?.meta?.customer?.hide_address_on_receipt &&
                useConfig().isDefaultReceiptTemplate
              "
            >
              <p>{{ reportData?.meta?.order?.address }},</p>
              <p>
                {{ reportData?.meta?.order?.city }}
              </p>
            </div>
          </template>
        </div>
      </div>

      <!-- PAYMENT METHOD -->
      <div class="border-t border-black">
        <div
          class="payment-type"
          :class="{
            'text-center px-[15px] py-[10px]':
              (useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale) ||
              !useConfig().isDefaultReceiptTemplate,
            'px-[15px] pt-[15px] pb-[10px]':
              useConfig().isDefaultReceiptTemplate &&
              !useConfig().isPosWholesale,
          }"
        >
          <p
            class="text-[16px] font-semibold"
            :class="[
              useConfig().isDefaultReceiptTemplate &&
              !useConfig().isPosWholesale
                ? 'leading-[30px]'
                : 'leading-[20px]',
            ]"
          >
            {{
              reportData?.meta?.order?.payment_status ===
                OrderPaymentStatus.PAID &&
              reportData?.meta?.order?.payment_type === "Account"
                ? "Paid"
                : reportData?.meta?.order?.payment_type
                  ? reportData?.meta?.order?.payment_type
                  : "Not Paid"
            }}
          </p>
        </div>
      </div>

      <!-- ORDER NOTES -->
      <div
        v-if="
          reportData?.meta?.order?.general_notes ||
          posStore.creatingOrderNotes?.text ||
          notice
        "
        class="border-t border-black"
      >
        <div
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate
              ? 'text-[10px] py-[5px]'
              : 'text-[12px] py-[15px]',
          ]"
          class="px-[15px] leading-[20px] order-notes"
        >
          <p>
            <span
              :class="{
                'font-bold': !useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              >Order Notes:
            </span>
            <span v-if="posStore.creatingOrderNotes?.text"
              >{{ posStore.creatingOrderNotes?.text }}.</span
            >
            <template v-else>
              {{ reportData?.meta?.order?.general_notes }}
              <span v-if="notice" v-html="notice"></span>
            </template>
          </p>
        </div>
      </div>

      <!-- PRODUCTS LIST -->
      <div class="border-t border-black">
        <div class="px-[15px]">
          <table>
            <thead>
              <tr
                :class="[
                  useConfig().isBrandedWholesaleReceiptTemplate
                    ? 'text-[10px]'
                    : 'text-[12px]',
                ]"
                class="leading-[18px]"
              >
                <th
                  :class="[
                    useConfig().isBrandedWholesaleReceiptTemplate
                      ? 'pt-[5px]'
                      : 'pt-[15px]',
                  ]"
                  class="pb-[5px]"
                >
                  <p>Units</p>
                </th>
                <th
                  :class="[
                    useConfig().isBrandedWholesaleReceiptTemplate
                      ? 'pt-[5px]'
                      : 'pt-[15px]',
                  ]"
                  class="pb-[5px] pl-[15px] text-left"
                >
                  <p>Product</p>
                </th>
                <th
                  v-if="
                    !useConfig().isDefaultReceiptTemplate ||
                    (useConfig().isDefaultReceiptTemplate &&
                      !useConfig().isPosWholesale)
                  "
                  :class="[
                    useConfig().isBrandedWholesaleReceiptTemplate
                      ? 'pt-[5px]'
                      : 'pt-[15px]',
                  ]"
                  class="pb-[5px] pl-[15px] text-right"
                >
                  <p>
                    {{
                      !useConfig().isPosWholesale ||
                      useConfig().isBrandedWholesaleReceiptTemplate
                        ? "Unit"
                        : "Price"
                    }}
                  </p>
                </th>
                <th
                  v-if="
                    !useConfig().isDefaultReceiptTemplate ||
                    (useConfig().isDefaultReceiptTemplate &&
                      !useConfig().isPosWholesale)
                  "
                  :class="[
                    useConfig().isBrandedWholesaleReceiptTemplate
                      ? 'pt-[5px]'
                      : 'pt-[15px]',
                  ]"
                  class="pb-[5px] pl-[15px] text-right"
                >
                  <p>Total</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(item, idx) in productList"
                :key="item?.order_item?.id"
              >
                <!-- main info -->
                <tr
                  class="text-[12px] leading-[18px]"
                  :class="{
                    'border-dashed':
                      !useConfig().isDefaultReceiptTemplate ||
                      (useConfig().isDefaultReceiptTemplate &&
                        !useConfig().isPosWholesale),
                    'border-t border-black':
                      (useConfig().isDefaultReceiptTemplate &&
                        !useConfig().isPosWholesale &&
                        idx === 0) ||
                      (useConfig().isDefaultReceiptTemplate &&
                        useConfig().isPosWholesale) ||
                      (!useConfig().isDefaultReceiptTemplate && idx === 0),
                  }"
                >
                  <td class="text-right align-top">
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{
                        item?.order_item?.allocated ||
                        item?.order_item?.allocated === 0
                          ? item?.order_item?.allocated
                          : item?.order_item?.quantity
                      }}
                    </p>
                  </td>
                  <td class="align-top pl-[15px] w-full">
                    <div
                      :class="[
                        useConfig().isBrandedWholesaleReceiptTemplate
                          ? 'py-[5px]'
                          : 'py-[10px]',
                      ]"
                      class="flex justify-between items-start"
                    >
                      <div class="flex flex-col gap-[10px] pr-[5px]">
                        <p
                          :class="{
                            'max-w-[150px]':
                              !useConfig().isBrandedWholesaleReceiptTemplate,
                          }"
                        >
                          <span
                            v-html="getProductName(item)"
                            :class="{
                              'font-bold':
                                !useConfig().isDefaultReceiptTemplate ||
                                (useConfig().isDefaultReceiptTemplate &&
                                  !useConfig().isPosWholesale),
                            }"
                            class="inline-flex gap-[5px]"
                          ></span>
                        </p>
                        <div v-if="item.addons?.length">
                          <p
                            v-for="ad in item.addons
                              .map((addon) => addon.value)
                              .flat(Infinity)"
                          >
                            {{
                              (ad as AddonValue).name
                                ? useCapitalize(
                                    (ad as AddonValue).name?.toString()
                                  )
                                : useCapitalize(
                                    (ad as AddonValue).slug
                                      ?.toString()
                                      .replace(/-/g, " ")
                                  )
                            }}
                          </p>
                        </div>
                      </div>
                      <p
                        v-if="
                          useConfig().isDefaultReceiptTemplate &&
                          useConfig().isPosWholesale
                        "
                      >
                        {{
                          item.addons?.length && item?.order_item?.price_total
                            ? useFormatPrice(item?.order_item?.price_total)
                            : item?.order_item?.allocated ||
                                item?.order_item?.allocated === 0
                              ? useFormatPrice(
                                  +item?.order_item.price *
                                    +item?.order_item.allocated
                                )
                              : useFormatPrice(
                                  +item?.order_item.price *
                                    +item?.order_item.quantity
                                )
                        }}
                      </p>
                    </div>
                  </td>
                  <td
                    v-if="
                      !useConfig().isDefaultReceiptTemplate ||
                      (useConfig().isDefaultReceiptTemplate &&
                        !useConfig().isPosWholesale)
                    "
                    class="text-right align-top pl-[10px]"
                  >
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{
                        item.addons?.length && item?.order_item?.price
                          ? useFormatPrice(item?.order_item?.price)
                          : useFormatPrice(+item?.order_item?.price)
                      }}
                    </p>
                  </td>
                  <td
                    v-if="
                      !useConfig().isDefaultReceiptTemplate ||
                      (useConfig().isDefaultReceiptTemplate &&
                        !useConfig().isPosWholesale)
                    "
                    class="text-right align-top pl-[10px]"
                  >
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{
                        item.addons?.length && item?.order_item?.price_total
                          ? useFormatPrice(item?.order_item?.price_total)
                          : item?.order_item?.allocated ||
                              item?.order_item?.allocated === 0
                            ? useFormatPrice(
                                +item?.order_item.price *
                                  +item?.order_item.allocated
                              )
                            : useFormatPrice(
                                +item?.order_item.price *
                                  +item?.order_item.quantity
                              )
                      }}
                    </p>
                  </td>
                </tr>
                <!-- item discount -->
                <tr
                  v-if="
                    item.order_item?.discount && !item.order_item?.is_sale_price
                  "
                >
                  <td class="text-right align-top">
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{
                        item?.order_item?.allocated ||
                        item?.order_item?.allocated === 0
                          ? item?.order_item?.allocated
                          : item?.order_item?.quantity
                      }}
                    </p>
                  </td>
                  <td class="align-top pl-[15px] w-full">
                    <div class="flex justify-between items-start py-[10px]">
                      <div class="flex flex-col gap-[10px] pr-[10px]">
                        <p class="max-w-[150px]">
                          <span
                            v-html="getProductName(item) + 'Discount'"
                            :class="{
                              'font-bold':
                                !useConfig().isDefaultReceiptTemplate ||
                                (useConfig().isDefaultReceiptTemplate &&
                                  !useConfig().isPosWholesale),
                            }"
                            class="inline-flex gap-[5px]"
                          ></span>
                        </p>
                      </div>
                      <p
                        v-if="
                          useConfig().isDefaultReceiptTemplate &&
                          useConfig().isPosWholesale
                        "
                      >
                        {{
                          item.addons?.length && item?.order_item?.price_total
                            ? useFormatPrice(item?.order_item?.price_total)
                            : item?.order_item?.allocated ||
                                item?.order_item?.allocated === 0
                              ? useFormatPrice(
                                  +item?.order_item.price *
                                    +item?.order_item.allocated
                                )
                              : useFormatPrice(
                                  +item?.order_item.price *
                                    +item?.order_item.quantity
                                )
                        }}
                      </p>
                    </div>
                  </td>
                  <td class="text-right align-top pl-[10px]">
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{
                        useFormatPrice(
                          item.order_item?.price + item.order_item?.discount
                        )
                      }}
                    </p>
                  </td>
                  <td class="text-right align-top pl-[10px]">
                    <p
                      :class="{
                        'font-bold':
                          !useConfig().isDefaultReceiptTemplate ||
                          (useConfig().isDefaultReceiptTemplate &&
                            !useConfig().isPosWholesale),
                      }"
                      class="py-[10px]"
                    >
                      {{ useFormatPrice(item.order_item?.discount_total) }}
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <!-- SUBTOTAL AND ADJUSTMENTS-->
      <div
        :class="{
          'border-dashed':
            !useConfig().isDefaultReceiptTemplate ||
            (useConfig().isDefaultReceiptTemplate &&
              !useConfig().isPosWholesale),
          'mt-[10px]': !useConfig().isBrandedWholesaleReceiptTemplate,
          'border-t border-black':
            !useConfig().isBrandedWholesaleReceiptTemplate ||
            (useConfig().isBrandedWholesaleReceiptTemplate &&
              adjustments?.length),
        }"
      >
        <div
          :class="{
            'pb-[7px]':
              useConfig().isBrandedWholesaleReceiptTemplate &&
              adjustments?.length,
            'py-[15px]': !useConfig().isBrandedWholesaleReceiptTemplate,
          }"
          class="px-[15px]"
        >
          <div
            v-if="!useConfig().isBrandedWholesaleReceiptTemplate"
            :class="{
              'font-bold text-[12px] leading-[18px]':
                !useConfig().isDefaultReceiptTemplate ||
                (useConfig().isDefaultReceiptTemplate &&
                  !useConfig().isPosWholesale),
            }"
            class="flex justify-between totals"
          >
            <p>Subtotal:</p>
            <p>
              {{ useFormatPrice(productListSubtotalPrice ?? 0) }}
            </p>
          </div>
          <!-- adjustments -->
          <template v-if="adjustments?.length">
            <div
              v-for="(fee, index) in adjustments"
              :key="index"
              :class="{
                'font-bold text-[12px] leading-[18px]':
                  !useConfig().isDefaultReceiptTemplate ||
                  (useConfig().isDefaultReceiptTemplate &&
                    !useConfig().isPosWholesale),
                'pt-[15px]': !useConfig().isBrandedWholesaleReceiptTemplate,
                'pt-[7px]': useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              class="flex justify-between"
            >
              <p>{{ fee.name }}:</p>
              <p>
                {{
                  fee.type === "shipping" && !fee.data?.is_inclusive
                    ? useFormatPrice(fee.data.price)
                    : useFormatPrice(fee.amount || fee.value)
                }}
              </p>
            </div>
          </template>
        </div>
      </div>

      <!-- LOAYLTY AND REWARDS -->
      <div
        v-if="
          useConfig().isLoyaltyRewards &&
          !useConfig().isBrandedWholesaleReceiptTemplate &&
          (!useConfig().isDefaultReceiptTemplate ||
            (useConfig().isDefaultReceiptTemplate &&
              useConfig().isPosWholesale))
        "
        class="mt-[10px] border-t border-black"
        :class="{
          'border-dashed':
            useConfig().isDefaultReceiptTemplate && !useConfig().isPosWholesale,
        }"
      >
        <div class="px-[15px] py-[15px]">
          <div class="flex justify-between totals">
            <p>
              Paid with Points (Redeemed {{ posStore.redeemedPoints || 0 }}):
            </p>
            <p>{{ useFormatPrice(-posStore.totals.paid_with_points || 0) }}</p>
          </div>
          <div class="flex justify-between pt-[15px]">
            <p>Volume Discount:</p>
            <p>
              {{
                useFormatPrice(
                  getAdjustmentsOptionValue("Volume Discount")?.amount || 0
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- BONUS POINTS -->
      <div
        v-if="
          useConfig().isLoyaltyRewards &&
          reportData?.meta?.order?.special_offer_notes &&
          useConfig().isDefaultReceiptTemplate &&
          !useConfig().isPosWholesale
        "
        class="border-t border-black"
      >
        <div class="p-[15px]">
          <div class="flex flex-col gap-[5px]">
            <div class="flex gap-[6px]">
              <img
                alt="gift-icon"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSAxLjEyNVYxNC44NzVNMS4zNzUgOEgxNy42MjVNNC44MTI1IDEyLjM3NUM3LjA1NjE2IDEyLjM3NSA4Ljg3NSAxMC41NTYyIDguODc1IDguMzEyNVY4TTE0LjE4NzUgMTIuMzc1QzExLjk0MzggMTIuMzc1IDEwLjEyNSAxMC41NTYyIDEwLjEyNSA4LjMxMjVWOE0yLjYyNSAxNC44NzVIMTYuMzc1QzE3LjA2NTQgMTQuODc1IDE3LjYyNSAxNC4zMTU0IDE3LjYyNSAxMy42MjVWMi4zNzVDMTcuNjI1IDEuNjg0NjQgMTcuMDY1NCAxLjEyNSAxNi4zNzUgMS4xMjVIMi42MjVDMS45MzQ2NCAxLjEyNSAxLjM3NSAxLjY4NDY0IDEuMzc1IDIuMzc1VjEzLjYyNUMxLjM3NSAxNC4zMTU0IDEuOTM0NjQgMTQuODc1IDIuNjI1IDE0Ljg3NVpNMTMuMTQyNyA3LjAwODkxQzExLjk2ODUgOC4xODMwOCA5LjYwNzE5IDcuODkyNzkgOS42MDcxOSA3Ljg5Mjc5QzkuNjA3MTkgNy44OTI3OSA5LjMxNjg5IDUuNTMxNDMgMTAuNDkxMSA0LjM1NzI2QzExLjIyMzMgMy42MjUwMyAxMi40MTA1IDMuNjI1MDMgMTMuMTQyNyA0LjM1NzI2QzEzLjg3NSA1LjA4OTQ5IDEzLjg3NSA2LjI3NjY4IDEzLjE0MjcgNy4wMDg5MVpNOC40Nzc0OCA0LjM1NzI4QzkuNjUxNjUgNS41MzE0NiA5LjM2MTM2IDcuODkyODEgOS4zNjEzNiA3Ljg5MjgxQzkuMzYxMzYgNy44OTI4MSA3IDguMTgzMTEgNS44MjU4MyA3LjAwODkzQzUuMDkzNTkgNi4yNzY3IDUuMDkzNTkgNS4wODk1MSA1LjgyNTgzIDQuMzU3MjhDNi41NTgwNiAzLjYyNTA1IDcuNzQ1MjQgMy42MjUwNSA4LjQ3NzQ4IDQuMzU3MjhaIiBzdHJva2U9IiMwRjE3MkEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
                class="w-[20px] h-[18px] rounded-x1"
              />
              <span class="font-bold text-[14px] leading-[20px]">
                Loyalty and Rewards
              </span>
            </div>

            <template v-if="reportData?.meta?.order?.special_offer_notes">
              <p v-for="note in reportData?.meta?.order?.special_offer_notes">
                {{ note.text }}
              </p>
            </template>
          </div>
        </div>
      </div>

      <!-- TOTAL AND TAX -->
      <div
        class="border-t border-black"
        :class="{
          'border-dashed':
            (useConfig().isDefaultReceiptTemplate &&
              !useConfig().isPosWholesale) ||
            useConfig().isBrandedWholesaleReceiptTemplate,
        }"
      >
        <div
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate
              ? 'px-[15px] py-[7px] gap-[3px]'
              : 'p-[15px] gap-[5px]',
          ]"
          class="flex flex-col"
        >
          <div
            class="flex justify-between font-bold text-[14px] leading-[20px] totals"
          >
            <p>Total:</p>
            <p>
              {{ useFormatPrice(productListPrice ?? 0) }}
            </p>
          </div>
          <div class="flex justify-between text-[12px] leading-[18px]">
            <p>Tax Included (GST):</p>
            <p>
              {{ useFormatPrice(reportData?.meta?.order?.tax ?? 0) }}
            </p>
          </div>
          <div class="flex justify-between text-[12px] leading-[18px]">
            <p>Less Payments:</p>
            <p>
              {{ useFormatPrice(reportData?.meta?.order?.paid_amount ?? 0) }}
            </p>
          </div>
          <div
            class="flex justify-between text-[12px] leading-[18px] font-bold"
          >
            <p>Balance</p>
            <p>
              {{ useFormatPrice(productListOutstandingAmount) }}
            </p>
          </div>
        </div>
      </div>

      <!-- CUSTOMER INFO (HOSPITALITY, RETAIL) -->
      <div
        v-if="
          useConfig().isDefaultReceiptTemplate &&
          !useConfig().isPosWholesale &&
          ((reportData?.meta?.customer?.bay_number &&
            reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
            reportData?.meta?.order?.type !== OrderType.PICKUP) ||
            (reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
              reportData?.meta?.order?.type !== OrderType.PICKUP &&
              reportData?.meta?.order?.address &&
              !reportData?.meta?.customer?.hide_address_on_receipt) ||
            ((reportData?.meta?.customer?.phone ||
              reportData?.meta?.customer?.mobile) &&
              reportData?.meta?.order?.type !== OrderType.IN_PERSON) ||
            (reportData?.meta?.order?.type === OrderType.DELIVERY &&
              (reportData?.meta?.order?.general_delivery_notes ||
                reportData?.meta?.address?.notes)))
        "
        class="border-t border-black customer"
      >
        <div
          class="p-[15px] flex flex-col gap-[5px] text-[14px] leading-[20px]"
        >
          <p
            v-if="
              !useConfig().isBrandedWholesaleReceiptTemplate &&
              reportData?.meta?.customer?.bay_number &&
              reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
              reportData?.meta?.order?.type !== OrderType.PICKUP
            "
            class="font-bold"
          >
            Bay Number:
            {{ reportData?.meta?.customer?.bay_number }}
          </p>
          <div
            v-if="
              reportData?.meta?.order?.type !== OrderType.IN_PERSON &&
              reportData?.meta?.order?.type !== OrderType.PICKUP &&
              reportData?.meta?.order?.address &&
              !reportData?.meta?.customer?.hide_address_on_receipt
            "
          >
            <p>{{ reportData?.meta?.order?.address }},</p>
            <p>{{ reportData?.meta?.order?.city }}</p>
          </div>

          <p
            class="font-bold"
            v-if="
              (reportData?.meta?.customer?.phone ||
                reportData?.meta?.customer?.mobile) &&
              reportData?.meta?.order?.type !== OrderType.IN_PERSON
            "
          >
            {{
              reportData?.meta?.customer?.phone ||
              reportData?.meta?.customer?.mobile
            }}
          </p>

          <div
            class="text-[12px] leading-[18px]"
            v-if="!useConfig().isLoyaltyRewards"
          >
            <p>
              Total Number of Orders:
              {{ reportData?.meta?.customer?.completed_orders ?? 0 }}
            </p>
          </div>

          <div
            v-if="
              reportData?.meta?.order?.type === OrderType.DELIVERY &&
              (reportData?.meta?.order?.general_delivery_notes ||
                reportData?.meta?.address?.notes)
            "
          >
            <p class="font-medium text-[12px] leading-[18px]">
              <span>Delivery Notes: </span>
              {{
                reportData?.meta?.order?.general_delivery_notes ||
                reportData?.meta?.address?.notes
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- BONUS POINTS -->
      <div
        v-if="
          (useConfig().isLoyaltyRewards ||
            useConfig().isBrandedWholesaleReceiptTemplate ||
            reportData?.meta?.customer?.account_balance) &&
          ((useConfig().isDefaultReceiptTemplate &&
            useConfig().isPosWholesale) ||
            !useConfig().isDefaultReceiptTemplate)
        "
        class="border-t border-black"
      >
        <div
          :class="[
            useConfig().isBrandedWholesaleReceiptTemplate
              ? 'px-[15px] py-[7px]'
              : 'p-[15px]',
          ]"
        >
          <div
            v-if="
              reportData?.meta?.customer?.account_balance ||
              useConfig().isBrandedWholesaleReceiptTemplate
            "
            :class="{ 'pb-[15px]': useConfig().isLoyaltyRewards }"
            class="flex flex-col gap-[15px]"
          >
            <div
              :class="[
                useConfig().isBrandedWholesaleReceiptTemplate
                  ? 'text-[12px]'
                  : 'text-[14px]',
              ]"
              class="flex justify-between leading-[20px] font-bold"
            >
              <p>Account Balance</p>
              <p>
                {{
                  useFormatPrice(reportData?.meta?.customer?.account_balance)
                }}
              </p>
            </div>
            <div
              class="text-[12px] leading-[18px]"
              v-if="
                !useConfig().isLoyaltyRewards &&
                !useConfig().isBrandedWholesaleReceiptTemplate
              "
            >
              <p>
                Total Number of Orders:
                {{ reportData?.meta?.customer?.completed_orders ?? 0 }}
              </p>
            </div>
          </div>

          <div
            v-if="useConfig().isLoyaltyRewards"
            class="flex flex-col gap-[5px]"
          >
            <div class="flex gap-[6px]">
              <img
                alt="gift-icon"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSAxLjEyNVYxNC44NzVNMS4zNzUgOEgxNy42MjVNNC44MTI1IDEyLjM3NUM3LjA1NjE2IDEyLjM3NSA4Ljg3NSAxMC41NTYyIDguODc1IDguMzEyNVY4TTE0LjE4NzUgMTIuMzc1QzExLjk0MzggMTIuMzc1IDEwLjEyNSAxMC41NTYyIDEwLjEyNSA4LjMxMjVWOE0yLjYyNSAxNC44NzVIMTYuMzc1QzE3LjA2NTQgMTQuODc1IDE3LjYyNSAxNC4zMTU0IDE3LjYyNSAxMy42MjVWMi4zNzVDMTcuNjI1IDEuNjg0NjQgMTcuMDY1NCAxLjEyNSAxNi4zNzUgMS4xMjVIMi42MjVDMS45MzQ2NCAxLjEyNSAxLjM3NSAxLjY4NDY0IDEuMzc1IDIuMzc1VjEzLjYyNUMxLjM3NSAxNC4zMTU0IDEuOTM0NjQgMTQuODc1IDIuNjI1IDE0Ljg3NVpNMTMuMTQyNyA3LjAwODkxQzExLjk2ODUgOC4xODMwOCA5LjYwNzE5IDcuODkyNzkgOS42MDcxOSA3Ljg5Mjc5QzkuNjA3MTkgNy44OTI3OSA5LjMxNjg5IDUuNTMxNDMgMTAuNDkxMSA0LjM1NzI2QzExLjIyMzMgMy42MjUwMyAxMi40MTA1IDMuNjI1MDMgMTMuMTQyNyA0LjM1NzI2QzEzLjg3NSA1LjA4OTQ5IDEzLjg3NSA2LjI3NjY4IDEzLjE0MjcgNy4wMDg5MVpNOC40Nzc0OCA0LjM1NzI4QzkuNjUxNjUgNS41MzE0NiA5LjM2MTM2IDcuODkyODEgOS4zNjEzNiA3Ljg5MjgxQzkuMzYxMzYgNy44OTI4MSA3IDguMTgzMTEgNS44MjU4MyA3LjAwODkzQzUuMDkzNTkgNi4yNzY3IDUuMDkzNTkgNS4wODk1MSA1LjgyNTgzIDQuMzU3MjhDNi41NTgwNiAzLjYyNTA1IDcuNzQ1MjQgMy42MjUwNSA4LjQ3NzQ4IDQuMzU3MjhaIiBzdHJva2U9IiMwRjE3MkEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
                class="w-[20px] h-[18px] rounded-x1"
              />
              <span class="font-bold text-[14px] leading-[20px]">
                Loyalty and Rewards
              </span>
            </div>
            <p>
              <span class="font-bold">{{
                reportData?.meta?.customer?.completed_orders ?? 0
              }}</span>
              Total Orders,
              <span class="font-bold">{{
                reportData?.order?.points_earned ?? 0
              }}</span>
              Points Earned,
              <span class="font-bold">{{
                (customersStore?.activeCustomer as CustomerSingle)
                  ?.loyalty_points ?? 0
              }}</span>
              Points Balance
            </p>

            <template v-if="reportData?.meta?.order?.special_offer_notes">
              <p v-for="note in reportData?.meta?.order?.special_offer_notes">
                {{ note.text }}
              </p>
            </template>
          </div>
        </div>
      </div>

      <!-- DELIVERY NOTES -->
      <div
        v-if="
          ((!useConfig().isBrandedWholesaleReceiptTemplate &&
            !useConfig().isDefaultReceiptTemplate) ||
            (useConfig().isDefaultReceiptTemplate &&
              useConfig().isPosWholesale)) &&
          reportData?.meta?.order?.type === OrderType.DELIVERY &&
          (reportData?.meta?.order?.general_delivery_notes ||
            reportData?.meta?.address?.notes)
        "
        class="border-t border-black delivery-notes"
      >
        <div class="p-[15px]">
          <p class="font-medium">
            <span class="font-bold">Delivery Notes: </span>
            {{
              reportData?.meta?.order?.general_delivery_notes ||
              reportData?.meta?.address?.notes
            }}
          </p>
        </div>
      </div>

      <!-- FOOTER -->
      <div
        :class="{
          'border-dashed': useConfig().isBrandedWholesaleReceiptTemplate,
        }"
        class="border-t border-black"
      >
        <div
          :class="{
            'text-center':
              useConfig().isDefaultReceiptTemplate &&
              useConfig().isPosWholesale,
            'pt-[15px]': !useConfig().isBrandedWholesaleReceiptTemplate,
            'pt-[7px]': useConfig().isBrandedWholesaleReceiptTemplate,
          }"
          class="px-[15px]"
        >
          <div
            v-if="
              !useConfig().isDefaultReceiptTemplate ||
              (useConfig().isDefaultReceiptTemplate &&
                !useConfig().isPosWholesale)
            "
            :class="[
              useConfig().isBrandedWholesaleReceiptTemplate ? '' : 'pb-[15px]',
            ]"
            class="font-bold"
          >
            <p
              :class="{
                'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              v-if="reportData?.meta?.order"
            >
              Placed:
              {{ formattedPlacedDate }}
            </p>
            <p
              v-if="
                !useConfig().isPrintHideTargetDate &&
                !useConfig().isBrandedWholesaleReceiptTemplate
              "
            >
              Target:
              {{
                route.name === useRoutesNames().posCheckoutThankyouId ||
                !reportData?.meta?.order?.target_date?.[0] ||
                reportData?.meta?.order?.is_express
                  ? "ASAP"
                  : reportData?.meta?.order?.target_date?.[0]
                    ? useDateFormatLib(
                        reportData?.meta?.order?.target_date?.[0],
                        `ddd, dd mmm, yy 'at' hh:MM TT`
                      )
                    : ""
              }}
            </p>

            <p
              v-if="
                route.query.confirmed_units &&
                useConfig().isBrandedWholesaleReceiptTemplate
              "
              :class="{
                'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
              }"
            >
              <span
                >Lines: {{ reportData?.meta?.order?.unit_checks?.lines }}
              </span>
              <span>
                Units: {{ reportData?.meta?.order?.unit_checks?.products }}
              </span>
            </p>
            <p
              v-if="reportData?.meta?.order?.sales_manager_firstname"
              :class="{
                'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
              }"
              class="leading-[18px]"
            >
              Sales Person:
              {{ reportData?.meta?.order?.sales_manager_firstname }}
            </p>
          </div>

          <p
            v-if="!useConfig().isBrandedWholesaleReceiptTemplate"
            class="font-bold text-[12px] leading-[18px]"
          >
            Thank you for ordering from
          </p>
          <p
            class="font-bold leading-[18px]"
            :class="{
              'mb-[5px]':
                (useConfig().isDefaultReceiptTemplate &&
                  useConfig().isPosWholesale) ||
                !useConfig().isDefaultReceiptTemplate,
              'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
            }"
          >
            {{ reportData?.meta?.store?.full_name }}
          </p>
          <p
            v-if="
              reportData?.meta?.store?.address?.address &&
              !useConfig().isBrandedWholesaleReceiptTemplate &&
              (!useConfig().isDefaultReceiptTemplate ||
                (useConfig().isDefaultReceiptTemplate &&
                  useConfig().isPosWholesale))
            "
            class="text-[12px] leading-[18px] mb-[5px]"
          >
            {{ reportData?.meta?.store?.address?.address }},{{
              reportData?.meta?.store?.address?.city
            }}
            {{ reportData?.meta?.store?.address?.province?.code }}
            {{ reportData?.meta?.store?.address?.postalcode }}
          </p>
          <div
            v-if="
              !useConfig().isBrandedWholesaleReceiptTemplate &&
              ((useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale) ||
                !useConfig().isDefaultReceiptTemplate)
            "
            :class="{
              'text-center justify-center':
                useConfig().isDefaultReceiptTemplate &&
                useConfig().isPosWholesale,
            }"
            class="flex gap-[10px] mb-[5px]"
          >
            <p
              v-if="
                (reportData?.meta?.store?.abn ||
                  reportData?.meta?.store?.phone) &&
                (!useConfig().isDefaultReceiptTemplate ||
                  (useConfig().isDefaultReceiptTemplate &&
                    useConfig().isPosWholesale))
              "
              class="text-[12px] leading-[18px]"
            >
              <span
                v-if="
                  reportData?.meta?.store?.abn &&
                  (!useConfig().isDefaultReceiptTemplate ||
                    (useConfig().isDefaultReceiptTemplate &&
                      useConfig().isPosWholesale))
                "
                >ABN:
                {{ reportData?.meta?.store?.abn }}
              </span>
              <span
                v-if="
                  reportData?.meta?.store?.phone &&
                  (!useConfig().isDefaultReceiptTemplate ||
                    (useConfig().isDefaultReceiptTemplate &&
                      useConfig().isPosWholesale))
                "
              >
                Phone:
                {{ reportData?.meta?.store?.phone }}</span
              >
            </p>
          </div>

          <p
            class="text-[10px] font-bold"
            v-if="
              useConfig().isBrandedWholesaleReceiptTemplate &&
              (reportData?.meta?.store?.payment_detail_notes ||
                reportData?.meta?.general_payment_detail_notes) &&
              (!useConfig().isDefaultReceiptTemplate ||
                (useConfig().isDefaultReceiptTemplate &&
                  useConfig().isPosWholesale))
            "
          >
            Payment Details
          </p>
          <p
            v-if="
              (reportData?.meta?.store?.payment_detail_notes ||
                reportData?.meta?.general_payment_detail_notes) &&
              (!useConfig().isDefaultReceiptTemplate ||
                (useConfig().isDefaultReceiptTemplate &&
                  useConfig().isPosWholesale))
            "
            :class="{
              'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
            }"
            class="leading-[18px]"
          >
            <span
              v-html="
                reportData?.meta?.store?.payment_detail_notes
                  ? reportData?.meta?.store?.payment_detail_notes
                  : reportData?.meta?.general_payment_detail_notes
              "
            ></span>
          </p>
          <p
            :class="{
              'text-[10px]': useConfig().isBrandedWholesaleReceiptTemplate,
              'pt-[15px]': !useConfig().isBrandedWholesaleReceiptTemplate,
            }"
            class="leading-[18px] font-bold"
          >
            Powered by www.jaffle.io
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { OrderType } from "~/types/general";
import { useReportsStore } from "~/store/reports";
import { usePosStore } from "~/store/pos";
import { InvoiceReport, AddonValue, ReportType } from "~/types/reports";
import { usePaymentStore } from "~/store/payment";
import { useGeneralStore } from "~/store/general";
import { useCustomersStore } from "~/store/customers";
import { CustomerSingle } from "~/types/customers";
import { useSettingsStore } from "~/store/settings";
import { OrderPaymentStatus } from "~/types/orders";

const emit = defineEmits(["loaded"]);

const props = defineProps<{
  orderId?: number;
  order?: any;
  notice?: string;
  noLoad?: boolean;
}>();

const route = useRoute();

const generalStore = useGeneralStore();
const reportsStore = useReportsStore();
const posStore = usePosStore();
const paymentStore = usePaymentStore();
const customersStore = useCustomersStore();
const settingsStore = useSettingsStore();

const reportData = ref();

const productList = computed(() =>
  route.query?.hide_zero_allocated
    ? reportData.value?.data?.filter((i) => !i.zero_allocated)
    : reportData.value?.data
);

const productListPrice = computed(() => {
  const res =
    reportData.value?.meta?.order?.total -
    reportData.value?.meta?.order?.subtotal;
  return productListSubtotalPrice.value + res;
});

const productListOutstandingAmount = computed(() => {
  const res =
    productListPrice.value - reportData.value?.meta?.order?.paid_amount;
  return res < 0 ? 0 : res;
});

const productListSubtotalPrice = computed(() => {
  const withoutZeroAllocatedPrice = productList.value?.reduce(
    (prev, curr) =>
      prev +
      (curr.addons?.length && curr?.order_item?.price_total
        ? curr?.order_item?.price_total
        : +curr?.order_item?.allocated || curr?.order_item?.allocated === 0
          ? +curr?.order_item?.price * +curr?.order_item?.allocated
          : +curr?.order_item?.price * +curr?.order_item?.quantity),
    0
  );
  return route.query?.hide_zero_allocated
    ? withoutZeroAllocatedPrice
    : reportData.value?.meta?.order?.subtotal;
});

// adjustments
const adjustments = computed(() => {
  const adjustments = reportData.value?.meta?.order?.adjustments;

  const cartItemsDiscount = productList.value?.reduce(
    (prev, curr) => prev + (curr?.order_item?.discount_total ?? 0),
    0
  );

  if (adjustments?.length) {
    return adjustments.map((adj) =>
      adj.type === "discount"
        ? {
            ...adj,
            amount: adj.amount + cartItemsDiscount,
          }
        : adj
    );
  }

  if (cartItemsDiscount) {
    return [
      {
        name: "Discount",
        type: "discount",
        amount: cartItemsDiscount,
      },
    ];
  }

  return [];
});

const placedDate = ref();
const placedTime = ref();

const formattedPlacedDate = computed(() => {
  const dateTime = new Date(
    placedDate.value?.getFullYear(),
    placedDate.value?.getMonth(),
    placedDate.value?.getDate(),
    parseInt(placedTime.value?.split(":")[0]),
    parseInt(placedTime.value?.split(":")[1]),
    parseInt(placedTime.value?.split(":")[2])
  );
  return placedDate.value
    ? useDateFormatLib(dateTime, `ddd, dd mmm, yy 'at' hh:MM TT`) || ""
    : "";
});

function getProductName(item) {
  let productName;
  if (route.query?.display_secondary_name && route.query?.product_parent_name) {
    if (item.product.product_parent_secondary && item.product.product_parent) {
      productName = item.product.product_parent_secondary;
    } else if (item.product.product_parent) {
      productName = item.product.product_parent;
    } else {
      productName =
        item.product.product_secondary &&
        item.product.product_secondary !== item.product.product
          ? item.product.product_secondary
          : item.product.product;
    }
  } else if (
    route.query?.display_secondary_name &&
    !route.query?.product_parent_name
  ) {
    productName =
      item.product.product_secondary &&
      item.product.product_secondary !== item.product.product
        ? item.product.product_secondary
        : item.product.product;
  } else if (
    !route.query?.display_secondary_name &&
    route.query?.product_parent_name
  ) {
    if (item.product.product_parent) {
      productName = item.product.product_parent;
    } else {
      productName = item.product.product;
    }
  } else {
    productName = item.product.product;
  }
  if (item.product.product_detailed_name?.properties?.length) {
    item.product.product_detailed_name.properties
      .filter((property) => property.symbol)
      .forEach((property) => {
        property.symbol.forEach((symbol) => {
          productName = productName.replace(
            property.title,
            `<img
              src="${generalStore.printAssets?.symbols[symbol]}"
              alt="symbol"
              style="height: 15px; width: 15px"
              class="symbol"
            />`
          );
        });
      });
  }

  productName = `${productName} ${
    item.order_item?.set_items?.length
      ? `- ${item.order_item.set_items.map((item) => item.name)?.join(", ")}`
      : ""
  } ${
    useConfig().isDefaultReceiptTemplate
      ? `(${
          item?.addons?.length && item?.order_item?.price
            ? useFormatPrice(item?.order_item?.price)
            : useFormatPrice(+item?.order_item?.price)
        })`
      : ""
  }`;

  return productName;
}

// COMPOSITES
function getCompositesAddons(item) {
  const setItems = item.order_item.set_items;

  if (setItems) {
    const values = setItems
      .map((set) => set.addons)
      ?.filter((a) => a)
      ?.flat()
      ?.map((addon) => addon.value)
      ?.flat()
      ?.filter((v) => v);

    return values?.map((v) => useCapitalize(v)).join(", ");
  }
}

function getAdjustmentsOptionValue(name) {
  return (
    posStore.totals?.adjustments?.find(
      (adjustment) => adjustment?.name === name
    ) || null
  );
}

// function replaceProperties(str, removeList) {
//   let newString = str;
//   removeList.forEach((item) => {
//     newString = newString.replace(item, "");
//   });
//   return newString;
// }

// function getProductName(newName) {
//   const item = newName;
//   item.product.productHtml = newName.order_item?.properties?.length
//     ? replaceProperties(
//         item.product?.product,
//         newName.order_item?.properties?.map((prop) => prop.title)
//       ) +
//       newName?.order_item?.properties
//         ?.map((prop) =>
//           prop.symbol_html?.length ? prop.symbol_html : prop.title
//         )
//         ?.join(" ")
//     : item.product?.product;
//   if (route.query?.display_secondary_name && route.query?.product_parent_name) {
//     if (
//       item.product?.product_parent_secondary?.length &&
//       item.product?.product_parent?.length
//     ) {
//       return `${item.product?.product_parent_secondary} (${useFormatPrice(
//         item?.order_item?.price ?? 0
//       )})`;
//     } else if (item.product?.product_parent?.length) {
//       return `${item.product?.product_parent} (${useFormatPrice(
//         item.order_item?.price ?? 0
//       )})`;
//     } else {
//       return item.product.product_secondary?.length &&
//         item.product.product_secondary !== item.product.product
//         ? `${item.product.product_secondary} (${useFormatPrice(
//             item.order_item?.price ?? 0
//           )})`
//         : `${item.product.productHtml} (${useFormatPrice(
//             item.order_item?.price ?? 0
//           )})` ||
//             `${item.product.product} (${useFormatPrice(
//               item.order_item?.price ?? 0
//             )})`;
//     }
//   } else if (
//     route.query?.display_secondary_name &&
//     !route.query?.product_parent_name
//   ) {
//     return item.product.product_secondary?.length &&
//       item.product.product_secondary !== item.product.product
//       ? `${item.product?.product_secondary} (${useFormatPrice(
//           item.order_item?.price ?? 0
//         )})`
//       : `${item.product.productHtml} (${useFormatPrice(
//           item.order_item?.price ?? 0
//         )})` ||
//           `${item.product?.product} (${useFormatPrice(
//             item.order_item?.price ?? 0
//           )})`;
//   } else if (
//     !route.query?.display_secondary_name &&
//     route.query?.product_parent_name
//   ) {
//     if (item.product.product_parent?.length) {
//       return `${item.product?.product_parent} (${useFormatPrice(
//         item.order_item?.price ?? 0
//       )})`;
//     } else {
//       return (
//         `${item.product?.productHtml} (${useFormatPrice(
//           item.order_item?.price ?? 0
//         )})` ||
//         `${item.product.product} (${useFormatPrice(
//           item.order_item?.price ?? 0
//         )})`
//       );
//     }
//   } else {
//     return (
//       `${item.product?.productHtml} (${useFormatPrice(
//         item.order_item?.price ?? 0
//       )})` ||
//       `${item.product?.product} (${useFormatPrice(
//         item.order_item?.price ?? 0
//       )})`
//     );
//   }
// }

async function getData(orderId?: number) {
  const id = orderId || props.orderId || (route as any).params.id;

  // modify order (Wholesale)
  if (route.query.is_pos && useConfig().isPosWholesale) {
    try {
      useIsLoading(true);
      await posStore.modifyQuickOrder(id, ReportType.INVOICE);
      useIsLoading(false);
    } catch (error) {}
  }

  useIsLoading(true);
  try {
    const res = await reportsStore.printInvoice(id);

    reportData.value = res;

    placedDate.value = new Date(
      reportData.value?.meta?.order?.order_date?.formatted_date
    );

    placedTime.value = reportData.value?.meta?.order?.order_date?.formatted_time
      ? `${reportData.value?.meta?.order?.order_date?.formatted_time}:00`
      : "00:00:00";

    reportData.value.meta.order.general_notes =
      reportData.value?.meta?.order?.general_notes
        ?.filter((i) => !i.is_private)
        ?.map((i) => i.text)
        ?.join(". ") ?? "";

    emit("loaded", id);
  } catch (error) {
  } finally {
    useIsLoading(false);
  }
}

defineExpose({
  getData,
});

onMounted(async () => {
  if (useIsEmpty(generalStore.printAssets)) {
    await generalStore.getPrintAssets();
  }

  if (props.noLoad) {
    if (props.order) {
      reportData.value = props.order;

      placedDate.value = new Date(
        reportData.value?.meta?.order?.order_date?.formatted_date
      );

      placedTime.value = reportData.value?.meta?.order?.order_date
        ?.formatted_time
        ? `${reportData.value?.meta?.order?.order_date?.formatted_time}:00`
        : "00:00:00";

      reportData.value.meta.order.general_notes =
        reportData.value?.meta?.order?.general_notes
          ?.filter((i) => !i.is_private)
          ?.map((i) => i.text)
          ?.join(". ") ?? "";
    }

    return;
  }

  useIsLoading(true);

  // PAY BY LINK LOGIC
  if (route.name === useRoutesNames().printContainerIdReceipt) {
    reportData.value = await paymentStore.getPayByLink(
      route.params.id,
      route.query?.token.toString(),
      +route.query?.deposit
    );

    placedDate.value = new Date(
      reportData.value?.meta?.order?.order_date?.formatted_date
    );

    placedTime.value = reportData.value?.meta?.order?.order_date?.formatted_time
      ? `${reportData.value?.meta?.order?.order_date?.formatted_time}:00`
      : "00:00:00";

    reportData.value.meta.order.general_notes =
      reportData.value?.meta?.order?.general_notes
        ?.filter((i) => !i.is_private)
        ?.map((i) => i.text)
        ?.join(". ") ?? "";

    useIsLoading(false);

    emit("loaded");

    return;
  }

  // REGULAR PRINT LOGIC
  await getData();

  useIsLoading(false);
});

onUnmounted(() => {
  reportData.value = {} as InvoiceReport;
});
</script>

<style lang="scss">
@media print {
  .shadow-xl {
    box-shadow: none !important;
    margin: 0 !important;
  }
}
</style>
